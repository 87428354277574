import React from 'react'
import styled, { css } from 'styled-components'
import { up, addFont, addResponsivity } from '../lib/styles'

const TextComponent = styled.div`
  ${({
    theme: { colors },
    fontSize,
    fontWeight = 400,
    weight,
    size,
    mobileSize,
    lineHeight = '150%',
    padding,
    margin,
    upperCase,
    letterSpacing,
    mobileLetterSpacing,
    maxWidth,
    mobileFontSize,
    mobileLineHeight,
    bp = 'mobile',
    noWrap,
    color = 'text',
    textAlign,
    mobileTextAlign,
    whiteSpace,
    font = 'noto',
    transition,
    appendCss,
    opacity,
  }) => {
    const desktopSize = size || fontSize
    const mobSize = mobileSize || mobileFontSize

    return css`
      ${addFont(font)}
      ${addResponsivity()}

      transition: ${transition};
      opacity: ${opacity};
      font-weight: ${weight || fontWeight};
      color: ${colors[color] || color};
      white-space: ${noWrap && 'nowrap'};
      padding: ${padding};
      white-space: ${whiteSpace};
      margin: ${margin};
      text-transform: ${upperCase && 'uppercase'};
      letter-spacing: ${mobileLetterSpacing || letterSpacing};
      max-width: ${maxWidth};

      font-size: ${mobSize || desktopSize};
      line-height: ${mobileLineHeight || lineHeight};
      text-align: ${mobileTextAlign || textAlign};

      ${appendCss}

      ${up(bp)} {
        font-size: ${desktopSize};
        line-height: ${lineHeight};
        letter-spacing: ${letterSpacing};
        text-align: ${textAlign};
      }

      strong {
        font-weight: 700;
      }
      /* because of links in perex. If it cause issues then move it directly to person and post */
      a {
        text-decoration: underline;

        ${color === 'white' &&
        css`
          color: white;
        `}

        ${(color === 'text' || color === 'black') &&
        css`
          color: ${colors.red};

          &:hover {
            text-decoration: none;
          }
        `}
      }
    `
  }}
`

const Text = props => <TextComponent {...props} />

// define basic styles used on page
export const BasicWhiteText = props => (
  <TextComponent
    fontSize="18px"
    lineHeight="150%"
    mobileFontSize="18px"
    mobileLineHeight="150%"
    color="white"
    {...props}
  />
)

export const ArticleText = props => (
  <TextComponent
    fontSize="18px"
    lineHeight="31px"
    mobileFontSize="17px"
    mobileLineHeight="160%"
    color="text"
    padding="0 0 24px"
    {...props}
  />
)

export default Text
