import React from 'react'
import { ArticleText } from './Text'
import Col from './Col'
import Gap from './Gap'

function ArticleQuestion({ children }) {
  return (
    <Col>
      <Gap gap="8px" mobileGap="0" />
      <ArticleText weight="700" padding="0">
        {children}
      </ArticleText>
      <Gap gap="8px" />
    </Col>
  )
}

export default ArticleQuestion
