import React from 'react'
import styled, { css } from 'styled-components'
import { up, addResponsivity, addFont } from '../lib/styles'

// default values belong to Page title
const Title = styled.span`
  ${({
    theme: { colors, fonts },
    fontSize,
    fontWeight = 800,
    weight,
    lineHeight,
    mobileFontSize,
    mobileLineHeight,
    maxWidth,
    mobileMaxWidth,
    textAlign,
    alignSelf,
    mobileTextAlign,
    display = 'block',
    bp = 'mobile', // can be px or bp name
    margin,
    color = 'cyan',
    letterSpacing,
    mobileLetterSpacing,
    upperCase,
    font = 'public',
    size,
    mobileSize,
    padding,
  }) => {
    const desktopSize = size || fontSize
    const mobSize = mobileSize || mobileFontSize

    return css`
      ${addFont(font)}
      font-weight: ${weight || fontWeight};
      max-width: ${mobileMaxWidth || maxWidth};
      align-self: ${alignSelf};
      display: ${display};
      color: ${colors[color] || color};
      text-transform: ${upperCase && 'uppercase'};

      font-size: ${mobSize || desktopSize};
      line-height: ${mobileLineHeight || lineHeight};
      text-align: ${mobileTextAlign || textAlign};
      letter-spacing: ${mobileLetterSpacing || letterSpacing};

      /* reset */
      margin: 0;
      margin: ${margin};
      padding: 0;
      padding: ${padding};

      ${up(bp)} {
        font-size: ${desktopSize};
        line-height: ${lineHeight};
        text-align: ${textAlign};
        letter-spacing: ${letterSpacing};
        max-width: ${maxWidth};
      }

      ${addResponsivity({ displayAs: display })}
    `
  }}
`

// define basic styles used on page
// PROTIP: preddefinovane mobile / desktop velikosti, lze ovlivnit nastavenim vlastniho BP

// all pages h1 title
Title.PageTitle = props => (
  <Title
    fontSize="70px"
    lineHeight="85px"
    mobileFontSize="32px"
    mobileLineHeight="38px"
    color="white"
    letterSpacing="2.8px"
    as={props.innerAs || 'h1'}
    {...props}
  />
)

Title.HomepageTitle = props => (
  <Title
    fontSize="64px"
    lineHeight="72px"
    mobileFontSize="40px"
    mobileLineHeight="48px"
    fontWeight="900"
    color="gold"
    as="h1"
    {...props}
  />
)

Title.QuizQuestionTitle = props => (
  <Title
    fontSize="32px"
    lineHeight="140%"
    mobileFontSize="24px"
    fontWeight="800"
    color="white"
    as="h1"
    {...props}
  />
)

Title.ArticleTitle = props => (
  <Title
    fontSize="56px"
    lineHeight="130%"
    mobileFontSize="32px"
    fontWeight="800"
    color="cyan"
    font="public"
    as="h1"
    textAlign="center"
    width="100%"
    {...props}
  />
)

Title.ArticleSectionTitle = props => (
  <Title
    fontSize="32px"
    lineHeight="140%"
    mobileFontSize="24px"
    fontWeight="800"
    color="cyan"
    font="public"
    as="h2"
    padding="16px 0 16px"
    {...props}
  />
)

Title.ArticleSectionSubTitle = props => (
  <Title
    fontSize="24px"
    lineHeight="150%"
    mobileFontSize="24px"
    mobileLineHeight="140%"
    fontWeight="800"
    color="cyan"
    font="public"
    as="h3"
    padding="0"
    // mobilePadding="0 0 16px"
    {...props}
  />
)

export default Title
