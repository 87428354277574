import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between, addFont } from '../lib/styles'
import Col from './Col'
import Gap from './Gap'
import Row from './Row'
import Text, { ArticleText } from './Text'
import Title from './Title'
import RH from './RH'

export const ListItemMarker = styled.div`
  ${({ theme: { colors } }) => css`
    counter-increment: number;
    position: relative;
    padding-left: 0;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    /* ${down('mobile')} {
      & > ${Title} {
        margin-left: 64px;
      }
    }

    ${up('mobile')} {
      padding-left: 64px;
    } */

    &::after {
      ${addFont('public')}
      font-weight: 700;
      font-size: 28px;
      background: ${colors.red};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 5px;
      color: white;
      position: absolute;
      content: counter(number) '.';
      top: 0;
      left: 0;
      border-radius: 400px;
      width: 43px;
      height: 48px;
    }
  `}
`

function ArticleItem({ children, title }) {
  if (title) {
    return (
      <Col>
        <Row alignItems="center">
          <ListItemMarker />
          <Gap gap="16px" />
          <Col>
            <Gap.Fluid min="0" max="8px" />
            <Title.ArticleSectionSubTitle>{title}</Title.ArticleSectionSubTitle>
          </Col>
        </Row>
        <Gap gap="3px" mobileGap="16px" />

        <Row>
          <Gap mobileGap="0" gap="64px" />
          <ArticleText>{children}</ArticleText>
        </Row>

        <Gap mobileGap="8px" gap="0" />
      </Col>
    )
  }

  return (
    <Col>
      <Row alignItems="flex-start">
        <ListItemMarker />
        <Gap gap="16px" />
        <Col>
          <Gap gap="4px" />
          <RH desktop>
            <ArticleText>{children}</ArticleText>
          </RH>
        </Col>
      </Row>
      <RH mobile>
        <Col>
          <Gap gap="8px" />
          <ArticleText>{children}</ArticleText>
        </Col>
      </RH>
    </Col>
  )
}

export default ArticleItem
