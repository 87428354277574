import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between } from '../lib/styles'
import Col from './Col'
import Gap from './Gap'
import Row from './Row'
import Text from './Text'
import QuoteIcon from './icons/QuoteIcon'

// import quoteIconPath from '../data/images/ico/quote.svg'

const Icon = styled.div`
  width: 40px;
  height: 32px;
  ${up('mobile')} {
    width: 50px;
    height: 40px;
  }
`

function ArticleQuote({ children, mobilePadded, name }) {
  return (
    <Col>
      <Gap gap="16px" />
      <Row responsive>
        {mobilePadded && <Gap mobileGap="0" gap="66px" />}
        <Icon>
          <QuoteIcon />
        </Icon>
        <Gap gap="24px" mobileGap="16px" />
        <Col>
          <Text
            color="cyan"
            font="public"
            weight="600"
            size="22px"
            lineHeight="140%"
          >
            {children}
          </Text>
          {name && (
            <>
              <Gap gap="8px" />
              <Text
                color="cyan"
                font="public"
                weight="700"
                size="17px"
                lineHeight="150%"
              >
                {name}
              </Text>
            </>
          )}
        </Col>
        <Gap mobileGap="0" gap="40px" />
      </Row>
      <Gap gap="40px" mobileGap="32px" />
    </Col>
  )
}

export default ArticleQuote
