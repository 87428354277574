import React from 'react'
import styled, { css } from 'styled-components'
import { up } from '../../lib/styles'

export default () => (
  <svg
    width="115"
    height="50"
    viewBox="0 0 115 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.942871 11.513C0.942871 10.2062 1.52709 9.55444 2.83264 9.55444H14.86V12.2693C14.86 13.574 14.2075 14.1932 12.9015 14.1932H6.4409V18.1441H13.657V22.6124H6.4409V26.9074H15.0317V29.6226C15.0317 30.9282 14.3787 31.5473 13.0728 31.5473H2.83264C1.52709 31.5473 0.942871 30.8944 0.942871 29.5876V11.513Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2642 11.513C17.2642 10.2062 17.8484 9.55444 19.1539 9.55444H26.3017C31.3192 9.55444 34.2045 11.8567 34.2045 16.1867C34.2045 19.1758 33.0711 21.0993 30.3562 22.201L34.6521 30.5844C34.7896 30.8944 34.9609 31.2036 34.9609 31.5473H30.2195C29.2228 31.5473 28.8103 31.5473 28.329 30.5506L24.4803 22.6124H22.7622V31.5473H17.2642V11.513ZM22.7622 14.1932V18.1441H25.8888C27.4705 18.1441 28.7065 17.9386 28.7065 16.1867C28.7065 14.4682 27.4705 14.1932 25.8888 14.1932H22.7622Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.2066 9.38306C47.7767 9.38306 50.1489 9.89732 50.1489 12.0283V14.1931H43.8942C42.2099 14.1931 41.7286 14.6406 41.7286 15.5329C41.7286 18.1801 51.282 17.6301 51.282 25.0517C51.282 28.5908 49.2547 31.7177 43.0691 31.7177C39.0146 31.7177 35.8188 31.066 35.8188 29.0725V26.9077H43.2066C44.7189 26.9077 45.4402 26.4272 45.4402 25.3617C45.4402 22.4751 35.9214 23.0252 35.9214 15.3953C35.9214 12.0283 38.1553 9.38306 43.2066 9.38306Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.399 9.55396V12.3367C66.399 13.6085 65.746 14.1927 64.4754 14.1927H61.7602V31.5469H56.2621V14.1927H51.6226V11.41C51.6226 10.1382 52.2759 9.55396 53.5469 9.55396H66.399Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.1162 11.513C68.1162 10.2062 68.7 9.55444 70.006 9.55444H82.0334V12.2693C82.0334 13.574 81.3804 14.1932 80.0748 14.1932H73.6142V18.1441H80.8303V22.6124H73.6142V26.9074H82.2047V29.6226C82.2047 30.9282 81.5521 31.5473 80.2465 31.5473H70.006C68.7 31.5473 68.1162 30.8944 68.1162 29.5876V11.513Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.44092 37.1779C1.44092 36.6468 1.6303 36.3536 2.14456 36.3536H5.31977C7.67234 36.3536 8.9417 37.504 8.9417 39.5642C8.9417 41.5722 7.67234 42.6876 5.31977 42.6876H3.05446V47.3388H1.44092V37.1779ZM3.05446 37.779V41.3141H5.21724C6.43514 41.3141 7.31127 40.8855 7.31127 39.5293C7.31127 38.2414 6.43514 37.779 5.21724 37.779H3.05446Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2676 47.3384V38.756H11.04C11.5554 38.756 11.7774 39.1689 11.8124 39.8215C12.4822 38.9285 13.3744 38.6711 14.027 38.6711C14.3181 38.6711 14.6281 38.7399 14.6281 39.101V40.199H14.1983C12.9446 40.199 12.3097 40.5082 11.8124 40.92V47.3384H10.2676Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0682 37.7967H15.5234V36.818C15.5234 36.3025 15.7128 36.0118 16.2271 36.0118H17.0682V37.7967ZM15.5234 38.7564H16.3477C16.8788 38.7564 17.0682 39.0495 17.0682 39.5642V47.3388H15.5234V38.7564Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4982 47.3731C21.1721 47.3731 21.0008 47.3562 20.8971 47.0132L18.3567 39.118C18.3057 38.9463 18.3057 38.8438 18.3057 38.7557H19.4898C19.8155 38.7557 19.9365 38.7557 20.0221 39.1011L22.0993 45.7764H22.1668L24.244 39.1011C24.3477 38.7557 24.4502 38.7557 24.7763 38.7557H25.96C25.96 38.8438 25.96 38.9632 25.9094 39.118L23.3168 47.0132C23.2146 47.3562 23.043 47.3731 22.7342 47.3731H21.4982Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.8179 47.3384C31.2687 47.3384 31.0624 47.0472 31.0624 46.6178V46.3424C30.3587 47.1317 29.4147 47.424 28.6604 47.424C27.1144 47.424 26.1538 46.4811 26.1538 45.0381C26.1538 42.8761 28.4191 42.3272 31.0624 42.2062V41.4C31.0624 40.2332 30.2731 39.9063 29.2253 39.9063H26.7549V39.4604C26.7549 38.7041 27.6467 38.6711 29.4488 38.6711C31.4231 38.6711 32.5903 39.5296 32.5903 41.3317V47.3384H31.8179ZM31.0624 43.1865C28.711 43.2371 27.7674 43.7522 27.7674 44.8507C27.7674 45.7944 28.4372 46.1373 29.3121 46.1373C29.9639 46.1373 30.6169 45.8462 31.0624 45.4349V43.1865Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.558 38.7564H38.4116V39.9064H36.558V45.1422C36.558 45.8797 36.6437 46.1543 37.1941 46.1543H38.4116V46.5495C38.4116 47.219 37.794 47.3738 36.9356 47.3738C35.1845 47.3738 35.0132 46.4128 35.0132 45.1422V39.9064H33.9316V39.0495L35.0132 38.7564L35.5621 37.3841C35.7338 37.0231 35.8544 36.8699 36.1632 36.8699H36.558V38.7564Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.3667 46.2067V46.6003C45.3667 47.2702 44.4568 47.4242 42.5682 47.4242C39.8908 47.4242 39.0669 45.45 39.0669 43.0472C39.0669 40.6452 39.9426 38.6718 42.5521 38.6718C44.663 38.6718 45.7611 39.9753 45.7611 41.8643C45.7611 42.2591 45.7611 42.6186 45.6759 43.444H40.6804C40.6804 45.4351 41.3499 46.2067 42.7403 46.2067H45.3667ZM44.1817 42.3785C44.1994 42.2241 44.1994 41.8811 44.1994 41.6749C44.1994 40.8506 43.8737 39.8209 42.5682 39.8209C41.3841 39.8209 40.7492 40.5764 40.6804 42.3785H44.1817Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.0015 37.1779C51.0015 36.6468 51.1908 36.3536 51.7051 36.3536H54.5884C56.9406 36.3536 58.1934 37.3323 58.1934 39.255C58.1934 40.4219 57.6611 41.2803 56.5461 41.6752C57.9184 42.0515 58.5883 42.9626 58.5883 44.3361C58.5883 46.3095 57.3354 47.3388 54.9841 47.3388H51.7051C51.1908 47.3388 51.0015 47.0481 51.0015 46.5158V37.1779ZM54.6053 41.023C55.7556 41.023 56.528 40.6623 56.528 39.4094C56.528 38.1389 55.6877 37.779 54.469 37.779H52.615V41.023H54.6053ZM54.8634 45.9147C56.0825 45.9147 56.9229 45.5198 56.9229 44.1636C56.9229 42.842 56.0825 42.4134 54.8634 42.4134H52.615V45.9147H54.8634Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.4234 47.3384C64.8741 47.3384 64.6679 47.0472 64.6679 46.6178V46.3424C63.9642 47.1317 63.0201 47.424 62.2658 47.424C60.7202 47.424 59.7593 46.4811 59.7593 45.0381C59.7593 42.8761 62.0246 42.3272 64.6679 42.2062V41.4C64.6679 40.2332 63.8786 39.9063 62.8312 39.9063H60.3604V39.4604C60.3604 38.7041 61.2522 38.6711 63.0539 38.6711C65.0289 38.6711 66.1958 39.5296 66.1958 41.3317V47.3384H65.4234ZM64.6679 43.1865C62.3165 43.2371 61.3728 43.7522 61.3728 44.8507C61.3728 45.7944 62.0427 46.1373 62.918 46.1373C63.5698 46.1373 64.2223 45.8462 64.6679 45.4349V43.1865Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.2764 47.3384V38.756H69.0488C69.598 38.756 69.8216 39.0491 69.8216 39.4604V39.7519C70.5252 38.9635 71.4178 38.6711 72.224 38.6711C73.8898 38.6711 74.7128 39.6997 74.7128 41.2979V47.3384H73.1862V41.3663C73.1862 40.3538 72.6019 39.9244 71.6233 39.9244C70.9715 39.9244 70.3016 40.2332 69.8216 40.6449V47.3384H68.2764Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.322 42.5332L81.4973 39.1018C81.7023 38.8594 81.8233 38.7564 82.1494 38.7564H83.4707C83.4707 38.8771 83.42 38.9981 83.3344 39.1018L79.8668 42.7563L83.3512 46.9962C83.4538 47.0996 83.4707 47.2194 83.4707 47.3388H82.0634C81.6686 47.3388 81.5479 47.1675 81.3091 46.8587L78.322 43.2018V47.3388H76.7769V36.818C76.7769 36.3017 76.9666 36.0118 77.4805 36.0118H78.322V42.5332Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.0668 37.7967H84.522V36.818C84.522 36.3025 84.7114 36.0118 85.2256 36.0118H86.0668V37.7967ZM84.522 38.7564H85.3462C85.8774 38.7564 86.0668 39.0495 86.0668 39.5642V47.3388H84.522V38.7564Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.1274 47.3384V38.756H88.9002C89.4491 38.756 89.6726 39.0491 89.6726 39.4604V39.7519C90.3763 38.9635 91.2689 38.6711 92.0755 38.6711C93.7409 38.6711 94.5643 39.6997 94.5643 41.2979V47.3384H93.0372V41.3663C93.0372 40.3538 92.4534 39.9244 91.4743 39.9244C90.8226 39.9244 90.1527 40.2332 89.6726 40.6449V47.3384H88.1274Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.944 46.4637C102.944 48.489 102.086 50 99.3739 50C97.4697 50 96.7142 49.8444 96.7142 49.1758V48.7813H99.1677C100.559 48.7813 101.399 48.3346 101.399 46.3938V46.1039C100.919 46.6704 100.129 47.1843 99.1508 47.1843C97.1255 47.1843 96.1987 45.6226 96.1987 42.9275C96.1987 40.2508 97.1255 38.6707 99.1508 38.6707C100.129 38.6707 100.919 39.1861 101.399 39.7522V39.478C101.399 39.0498 101.623 38.7567 102.172 38.7567H102.944V46.4637ZM99.6831 45.9149C100.353 45.9149 100.954 45.6057 101.399 45.1932V40.6272C100.954 40.2158 100.353 39.9066 99.6831 39.9066C98.3265 39.9066 97.7954 40.9894 97.7954 42.9275C97.7954 44.884 98.3265 45.9149 99.6831 45.9149Z"
      fill="#01497C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.486 0C102.577 0 103.534 0.409316 104.33 1.2187C105.126 2.02728 105.531 3.01358 105.531 4.12533C105.531 5.26603 105.133 6.23986 104.338 7.04683C103.543 7.85742 102.577 8.26473 101.486 8.26473C100.37 8.26473 99.4101 7.85742 98.6313 7.04683C97.8388 6.23986 97.4399 5.26603 97.4399 4.12533C97.4399 3.01358 97.8388 2.03774 98.6209 1.23076C99.4101 0.41575 100.37 0 101.486 0ZM114.918 16.3658V13.858C114.918 11.4838 112.994 9.55581 110.62 9.55581H92.3476C89.9713 9.55581 88.0498 11.4838 88.0498 13.858V23.3081H108.432V24.7395H88.0498V27.2485C88.0498 29.6215 89.9713 31.5455 92.3476 31.5455H110.62C112.994 31.5455 114.918 29.6215 114.918 27.2485V17.7972H94.539V16.3658H114.918Z"
      fill="#E12116"
    />
  </svg>
)
