// everything with styles, theme etc. goes here
import styled, { css } from 'styled-components'

// this project colors, then used in theme colors
const projectColors = {
  white: '#ffffff',
  black: '#000000',
  textGrey: '#807D76',
  red: '#C24542',
  gold: '#E7D6B1',
  lightGold: '#FFF9EB',
  cyan: '#1B4050',
}

// theme colors
export const colors = {
  ...projectColors,
  primary: projectColors.red,
  secondary: projectColors.cyan,
  text: projectColors.black,
  textGrey: projectColors.black,
}

// theme vars accessible in every component
export const theme = {
  colors,
  breakpoints: {
    // from bootstrap for info only
    // sm: '576px',
    // md: '768px',
    // lg: '992px',
    // xl: '1200px',

    origin: 1,
    mobile: 768, // main breakpoint for mobile
    // menu: 768, // bp for hamburger -- no menu in this project
    pageWidth: 1280, // also bp for ...
    infinity: 99999, // also bp for ...
  },
  fonts: {
    noto: '"Noto Serif", serif',
    public: '"Public Sans", sans-serif',
    // add VF appendix for automatic variable fonts!
    publicVF: '"Public Sans VF", sans-serif',
  },
  pageSidePadding: '24px',
  mobilePageSidePadding: '24px',
  sizes: {
    // number constants, used for breakpoints
    pageWidth: 1280, // default page width
    // maxMenuWidth: 1920,
  },
  sizesPx: {
    // same sizes as above, but in pixel string
    pageWidth: '1280px',
  },
}

// style helpers and snippets
// media queries helpers, if arg is string, then it's bp name, otherwise it's pixel value
export const down = value =>
  `@media (max-width: ${
    typeof value === 'string' ? theme.breakpoints[value] : value
  }px)`
export const between = (value, value2) => `@media (min-width: ${
  typeof value === 'string' ? theme.breakpoints[value] : value
}px) and
  (max-width: ${
  typeof value2 === 'string' ? theme.breakpoints[value2] : value2
}px)`

export const up = value =>
  `@media (min-width: ${
    typeof value === 'string' ? theme.breakpoints[value] : value
  }px)`

export const desktop = `@media (min-width: ${theme.breakpoints.mobile}px)`

export const addFont = family => {
  const fullName = theme.fonts[family]
  const fullNameVF = theme.fonts[`${family}VF`]

  if (!fullNameVF) {
    return `font-family: ${fullName || family};`
  }
  return css`
    font-family: ${fullName || family};

    @supports (font-variation-settings: normal) {
      font-family: ${fullNameVF};
    }
  `
}

export const fontDim = (size, lineHeight) =>
  `font-size: ${size};
  line-height: ${lineHeight};`

// adds snippets which ResponsivityHelper uses
// props can be bp names or pixels
// to use mobile first, use **After, which uses min-width
export const addResponsivity = ({ displayAs: displayAsArg } = {}) => css`
  ${({
    hideBelow,
    showBelow,
    hideAfter,
    showAfter,
    displayAs = displayAsArg || 'block',
    hideBetween,
    showBetween,
    mobile, // useful shorthand for hideAfter="mobile", breakpoint can be changed via bp prop
    desktop, // useful shorthand as 'mobile'
    bp = 'mobile', // or can be changed to this bp!!! B-)
  }) => css`
    display: ${displayAs};

    ${hideBelow &&
    css`
      display: ${displayAs};
      ${down(hideBelow)} {
        display: none;
      }
    `}
    ${showBelow &&
    css`
      display: none;
      ${down(showBelow)} {
        display: ${displayAs};
      }
    `}
    ${showAfter &&
    css`
      display: none;
      ${up(showAfter)} {
        display: ${displayAs};
      }
    `}
    ${hideAfter &&
    css`
      display: ${displayAs};
      ${up(hideAfter)} {
        display: none;
      }
    `}
    ${showBetween &&
    css`
      display: none;
      ${between(...showBetween)} {
        display: ${displayAs};
      }
    `}
    ${hideBetween &&
    css`
      display: ${displayAs};
      ${between(...hideBetween)} {
        display: none;
      }
    `}
    ${mobile &&
    css`
      display: ${displayAs};
      ${up(bp)} {
        display: none;
      }
    `}
    ${desktop &&
    css`
      display: none;
      ${up(bp)} {
        display: ${displayAs};
      }
    `}
  `}
`

// export const addPagePadding = () => css`
//   ${({ theme: { pageSidePadding, mobilePageSidePadding, breakpoints } }) => css`
//     padding-left: ${mobilePageSidePadding};
//     padding-right: ${mobilePageSidePadding};
//     ${up(breakpoints.mobile)} {
//       padding-left: ${pageSidePadding};
//       padding-right: ${pageSidePadding};
//     }
//   `}
// `

// export const addFullWidth = () => css`
//   position: relative;
//   width: calc(100vw - 16px);
//   width: 100vw;
//   left: 50%;
//   transform: translateX(-50%);
//   ${up(1440)} {
//     max-width: 1440px;
//   }
// `
