const React = require('react')
const Layout = require('./src/layouts/DefaultLayout').default
const Providers = require('./src/layouts/Providers').default

// e.g. redux stores goes there
exports.wrapRootElement = ({ element }) => <Providers>{element}</Providers>

exports.wrapPageElement = ({ element, props }) => (
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  <Providers>
    <Layout {...props}>{element}</Layout>
  </Providers>
)


exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}


