import React from 'react'
import styled, { css } from 'styled-components'
import { up } from '../../lib/styles'

export default () => (
  <svg
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1.56201L7 7.56201L1 13.562" stroke="#C24542" strokeWidth="2" />
  </svg>
)
