import { css } from 'styled-components'
// https://google-webfonts-helper.herokuapp.com/fonts/noto-serif?subsets=latin,latin-ext

const generateFont = ({
  filename,
  filenames,
  familyName,
  localFamilyName,
  style = 'normal',
  weight,
  format = 'woff2',
}) => `@font-face {
    font-family: '${familyName}';
    font-style: ${style};
    font-display: swap;
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;

    font-weight: ${weight};
    src: local(${localFamilyName || familyName}),
    ${filename ? `url(${filename}) format('${format}'), ` : ''}
    ${
  filenames
    ? filenames
      .map(filename => `url(${filename}) format('${format}')`)
      .join(', ')
    : ''
};
  }
`

const generateVariableFont = ({ familyName, formats, filename, weights }) => `
    @font-face {
      font-family: '${familyName}';
      font-style: normal;
      font-display: swap;
      font-weight: ${weights};
      font-weight: 400 900;
      font-stretch: 100;
      unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;

      src: ${formats
    .filter(a => a)
    .map(format => `url('${filename}') format('${format}')`)
    .join(', ')};
    }
  `
const generateFonts = config =>
  config
    .map(familyConfig =>
      familyConfig.files
        .map(file =>
          generateFont({
            familyName: familyConfig.familyName,
            filename: file.filename,
            filenames: file.filenames,
            weight: file.weight,
            style: file.style,
            format: familyConfig.format,
          }))
        .join('\r\n'))
    .join('\r\n')

// @font-face {
//   font-family: 'Noto Serif';
//   font-style: normal;
//   font-display: swap;
//   font-weight: 400;
//   src: local('Noto Serif'),
//     url(${fontFiles.NotoSerifRegular}) format('truetype');
// }
// @font-face {
//   font-family: 'Noto Serif';
//   font-style: normal;
//   font-display: swap;
//   font-weight: 600;
//   src: local('Noto Serif'), url(${fontFiles.NotoSerifBold}) format('truetype');
// }

// eslint-disable-next-line import/prefer-default-export
export const getFontsCss = ({ variableFonts, fonts }) => css`
  ${variableFonts.map(generateVariableFont).join('\r\n')};
  ${generateFonts(fonts)}
`
