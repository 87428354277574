import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between } from '../lib/styles'
import Col from './Col'
import Gap from './Gap'
import Row from './Row'
import Text from './Text'

const GdpCol = ({ number, label }) => (
  <Col maxWidth="233px" mobileMaxWidth="220px">
    <Text
      color="red"
      weight="700"
      size="100px"
      mobileSize="80px"
      lineHeight="100%"
    >
      {number}
    </Text>
    <Gap gap="8px" mobileGap="8px" />

    <Text color="red" weight="700" size="18px">
      {label}
    </Text>
  </Col>
)

function GDPNumbers({ variant = 'gdp' }) {
  return (
    <Col>
      <Gap gap="16px" mobileGap="0" />
      <Row
        responsive
        mobileAlignItems="center"
        alignItems="flex-start"
        bp={550}
      >
        {variant === 'gdp' && (
          <>
            <GdpCol
              label="světového HDP generují rodinné firmy"
              number="80 %"
            />

            <Gap gap="100px" mobileGap="24px" />

            <GdpCol label="českého HDP generují rodinné firmy" number="50 %" />
          </>
        )}
        {variant === 'generation' && (
          <>
            <GdpCol
              label="českých firem je ve fázi první generační výměny"
              number="61 %"
            />
          </>
        )}
      </Row>
      <Gap gap="40px" mobileGap="32px" />
    </Col>
  )
}

export default GDPNumbers
