/**
 * just mapping from constants to pageKeys
 * keys are free, values has to be pageKeys in ./pagesConfig
 * which is either generated /page-name for pages or template filename
 */
exports.links = {
  HOMEPAGE: '/',
  QUIZ: '/kviz',
  ARTICLE: 'ArticleTemplate',
  RESULTS_ROOT: '/vysledky',
}

// ids of the elements
exports.anchors = {}
