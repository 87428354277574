import NotoSerifBold from './NotoSerif-Bold.woff2'
import NotoSerifRegular from './NotoSerif-Regular.woff2'
import NotoSerifBold2 from './NotoSerif-Bold.woff'
import NotoSerifRegular2 from './NotoSerif-Regular.woff'
import PSVF from './Public-Sans-VF.woff2'
import PSBlack from './PublicSans-Black.woff2'
import PSBold from './PublicSans-Bold.woff2'
import PSMedium from './PublicSans-Medium.woff2'
import PSRegular from './PublicSans-Regular.woff2'
import PSSemiBold from './PublicSans-SemiBold.woff2'
import PSExtraBold from './PublicSans-ExtraBold.woff2'

export const fonts = [
  {
    familyName: 'Noto Serif',
    format: 'woff2',
    files: [
      {
        weight: 400,
        filenames: [NotoSerifRegular, NotoSerifRegular2],
        style: 'normal', // custom
      },
      {
        weight: 700,
        filenames: [NotoSerifBold, NotoSerifBold2],
      },
    ],
  },
  {
    familyName: 'Public Sans',
    format: 'woff2',
    files: [
      {
        weight: 400,
        filename: PSRegular,
      },
      {
        weight: 500,
        filename: PSMedium,
      },
      {
        weight: 600,
        filename: PSSemiBold,
      },
      {
        weight: 700,
        filename: PSBold,
      },
      {
        weight: 800,
        filename: PSExtraBold,
      },
      {
        weight: 900,
        filename: PSBlack,
      },
    ],
  },
]

export const variableFonts = [
  {
    familyName: 'Public Sans VF',
    formats: ['woff2 supports variations', 'woff2-variations', 'woff2'],
    filename: PSVF,
    weights: '400 900',
  },
]
