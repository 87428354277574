import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between } from '../lib/styles'

import { lightenDarkenColor } from '../lib/adjustColor'
import Text from './Text'
import Gap from './Gap'
import WhiteArrowIcon from './icons/WhiteArrowIcon'
import RedArrowIcon from './icons/RedArrowIcon'

const RedArrowWrapper = styled.div`
  font-size: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  transition: 0.3s;
`

const Wrapper = styled.div`
  ${({ theme: { colors }, type }) => css`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    justify-content: center;
    align-items: center;

    ${type === 'primary' &&
    css`
      padding: 15px 32px;
      background-color: ${colors.red};
      box-shadow: 4px 4px 0 0 black;

      padding: 15px 20px;
      ${up(360)} {
        padding: 15px 32px;
      }
      &:hover {
        background-color: ${lightenDarkenColor(colors.red, -20)};
      }
    `}

    &:hover ${RedArrowWrapper} {
      transform: translate(3px, 0);
    }

    ${up('mobile')} {
      /* some reponsivity */
    }
  `}
`

function Button({ onClick, children, type, ...rest }) {
  let textProps = {
    font: 'public',
    size: '15px',
  }

  let ArrowComponent = ''
  let gapSize = ''

  let height

  if (type === 'primary') {
    textProps = {
      ...textProps,

      weight: '700',
      color: 'white',
      upperCase: true,
      lineHeight: '120%',
      whiteSpace: 'nowrap',
    }
    // arrowPath = whiteArrowPath
    ArrowComponent = WhiteArrowIcon
    gapSize = '13px'
    height = 18
  }
  if (type === 'secondary') {
    textProps = {
      ...textProps,
      weight: '600',
      color: 'red',
      lineHeight: '150%',
    }
    // arrowPath = redArrowPath
    ArrowComponent = props => (
      <RedArrowWrapper>
        <RedArrowIcon {...props} />
      </RedArrowWrapper>
    )
    gapSize = '11px'
    height = 22
  }

  return (
    <Wrapper onClick={onClick} type={type} {...rest}>
      <Text {...textProps}>{children}</Text>
      {ArrowComponent && (
        <>
          <Gap gap={gapSize} />
          <ArrowComponent alt="" width="9" height={height} />
        </>
      )}
    </Wrapper>
  )
}

export default Button
