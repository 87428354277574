import React from 'react'
import styled, { css } from 'styled-components'
import SpecialTopBar from '../components/SpecialTopBar'

const PageWrapper = styled.div`
  position: relative;
  z-index: 1;
  /* overflow-x: hidden; */
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  min-height: 100vh;
`

// eslint-disable-next-line react/prefer-stateless-function
class DefaultLayout extends React.Component {
  render() {
    // these props are exactly same as pageProps injected by gatsby
    // console.log('this.props LAYOUT', this.props)
    const {
      location: { pathname },
      pageContext,
      path,
    } = this.props

    return (
      <>
        <PageWrapper>
          <SpecialTopBar />
          {this.props.children}
        </PageWrapper>
      </>
    )
  }
}

export default DefaultLayout
