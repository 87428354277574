/* eslint-disable react/prop-types */
import React from 'react'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { up, addResponsivity } from '../lib/styles'
import { links, anchors } from '../links'

// this is the style
const LinkWrapper = styled.div`
  ${({
    theme: { colors },
    fontSize,
    fontWeight = 400,
    alignSelf,
    mobileFontSize,
    lineHeight = '20px',
    bp = 'mobile',
    width,
    noStyle,
    color = 'text',
  }) => css`
    display: inline;
    width: ${width};
    ${addResponsivity({ displayAs: 'inline' })};

    ${!noStyle &&
    css`
    & > a, & > span {
      transition: 0.1s;
      text-decoration: none;
      display: inline;
      font-weight: ${fontWeight};
      align-self: ${alignSelf};
      line-height: ${lineHeight};
      cursor: pointer;
      color: ${colors[color] || color};
      


      &:hover {
        text-decoration: none;
      }


      font-size: ${mobileFontSize || fontSize};
      ${up(bp)} {
        font-size: ${fontSize};
      }
    `}
  `}
`

const scrollTo = anchor => {
  const target = document.getElementById(anchor)
  if (target) {
    const pos = target.getBoundingClientRect().top + window.scrollY
    // console.log(pos)

    window.scroll({
      top: pos - 90, // subtract height of pinned menu on desktop
      behavior: 'smooth',
    })
  } else {
    console.log('NO TARGET!!!')
  }
}

const serializeParams = params =>
  `?${Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('$')}`

// automatically fill route path with translated route
// automatically prepend path with /en/ or /de/ etc...
// when preferLang --> generate link in that lang ===> use it to switch to other lang
const LinkComponent = ({
  to, // page route constant or dynamic route with /key/slug (eg. blog/article-slug)
  asSpan,
  asA,
  href, // only for asA
  target,
  alt,
  onClick,
  children,
  anchor,
  params,
  rel,
  title,
  ...rest
}) => {
  let finalPath = to

  if (params) {
    finalPath = `${finalPath}${serializeParams(params)}`
  }
  if (anchor) {
    finalPath = `${finalPath}#${anchor}`
  }

  const handleClick = e => {
    // scrolling behaviour
    if (anchor) {
      // prevent redirecting to page
      e.preventDefault()

      const isAnotherPage = !finalPath.startsWith('#')

      // start scrolling
      setTimeout(
        () => {
          scrollTo(anchor)
        },
        isAnotherPage ? 600 : 50,
      )

      // trigger user action
      if (onClick) {
        onClick(e)
      }

      // if it's another page
      if (isAnotherPage) {
        navigate(finalPath)
      }
    } else {
      // trigger user action
      if (onClick) {
        onClick(e)
      }
    }
  }

  return (
    <LinkWrapper {...rest}>
      {asSpan && <span onClick={handleClick}>{children}</span>}
      {asA && (
        <a title={title} href={href} rel={rel} target={target} alt={alt} onClick={handleClick}>
          {children}
        </a>
      )}
      {!asSpan && !asA && (
        <GatsbyLink title={title} to={finalPath} onClick={handleClick}>
          {children}
        </GatsbyLink>
      )}
    </LinkWrapper>
  )
}

// define common link styles
const Link = props =>
  <LinkComponent {...props} />



// Link.TextLink = styled(Link)`
//   a {
//     font-weight: 700;
//     color: inherit;
//   }
// `

// Link.OrangeLink = styled(Link)`
//   ${({ theme: { colors } }) => css`
//     a {
//       color: ${colors.orange};
//       font-weight: 700;
//       letter-spacing: 3px;
//       line-height: 25px;
//       text-decoration: underline;
//       text-transform: uppercase;
//       font-size: 15px;
//       &:hover {
//         color: ${colors.darkOrange};
//       }
//     }
//   `}
// `

// PAGE KEYS CONSTANTS
Object.keys(links).forEach(link => {
  Link[link] = links[link]
})
// And Anchors keys
Link.anchors = anchors

Link.scrollTo = scrollTo

export default Link
