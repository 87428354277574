import React, { Component, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { addResponsivity, up, down, between } from '../lib/styles'

import PartnerLogo1 from './icons/PartnerLogo1'
import PartnerLogo2 from './icons/PartnerLogo2'

import Button from './Button'
import RH from './RH'
import Row from './Row'
import Gap from './Gap'
import Col from './Col'
import Link from './Link'
import Title from './Title'
import Text from './Text'

const iconsConfig = {
  '10-rules': {
    width: 88,
    height: 89,
    mobileWidth: 79,
    mobileHeight: 81,
    hpWidth: 104,
    hpHeight: 109,
    gapToTitle: 40,
    gapToTitleHp: 32,
    gapToTop: 4,
    gapToTopHp: 10,
    gapToTopMobile: 19,
  },
  quotes: {
    width: 40,
    height: 32,
    mobileWidth: 40,
    mobileHeight: 32,
    gapToTitle: 24,
    gapToTitleMobile: 12,
    gapToTop: 0,
    responsive: true,
  },
  quiz: {
    width: 100,
    height: 160,
    mobileWidth: 80,
    mobileHeight: 125,
    gapToTitle: 32,
    gapToTitleMobile: 20,
    gapToTop: 0,
  },
  podcast: {
    width: 95,
    height: 48,
    mobileWidth: 196,
    mobileHeight: 40,
    gapToTitle: 32,
    gapToTitleMobile: 16,
    gapToTop: 0,
    responsive: true,
  },
}

const Wrapper = styled.div`
  ${({
    theme: { colors },
    bgColor,
    maxWidth,
    hpCols,
    isQuiz,
    padding,
    mobilePadding,
    width,
  }) => css`
    display: flex;
    flex-direction: column;
    background: ${colors[bgColor] || bgColor};
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    z-index: 1;

    ${hpCols &&
    css`
      max-width: ${maxWidth}px;
      padding: 30px;

      ${isQuiz &&
      css`
        padding: 32px 26px 48px;
      `}
    `}

    ${!hpCols &&
    css`
      padding: 16px;
      ${up(360)} {
        padding: 24px;
      }
      ${up('mobile')} {
        padding: 32px;
      }
    `}


    width: ${width};
    padding: ${mobilePadding || padding};
    ${up('mobile')} {
      padding: ${padding};
    }
  `}
`

const HpQuizIcon = styled.img`
  ${() => css`
    width: 195px;
    height: 311px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
    z-index: -1;
  `}
`

const LeftIcon = styled.img`
  ${({
    theme: { colors },
    width,
    height,
    mobileWidth,
    mobileHeight,
    hpWidth,
    hpHeight,
    hpCols,
  }) => css`
    width: ${mobileWidth}px;
    height: ${mobileHeight}px;
    ${up('mobile')} {
      width: ${width}px;
      height: ${height}px;

      ${hpCols &&
      css`
        width: ${hpWidth || mobileWidth}px;
        height: ${hpHeight || mobileHeight}px;
      `}
    }
  `}
`

const RightGatsbyImage = styled(GatsbyImage)`
  ${({ theme: { colors }, mobileWidth, hpCols, width }) => css`
    position: absolute !important;
    bottom: 0;
    right: 0;
    max-width: ${mobileWidth};
    ${up('mobile')} {
      max-width: none;
      max-width: ${width};

      ${hpCols &&
      css`
        max-width: ${mobileWidth};
      `}
    }
  `}
`

const PartnerBox = ({ hpCols }) => (
  <Wrapper
    bgColor="white"
    maxWidth={372}
    hpCols={hpCols}
    padding="24px 30px 32px"
    mobilePadding="20px 20px 32px"
    width="100%"
  >
    <Row>
      {/* <Gap gap="8px" /> */}

      <Text
        font="public"
        weight="500"
        size="13px"
        lineHeight="150%"
        upperCase
        letterSpacing="1px"
        color="#BFBFBF"
      >
        Partner speciálu
      </Text>
    </Row>

    <Gap gap="16px" />

    <Row justifyContent="center">
      <a
        title="Česká spořitelna pro Firmy a podnikatele"
        href=" https://www.csas.cz/cs/firmy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <PartnerLogo1 />
      </a>
      <Gap.Fluid max="32px" min="16px" />
      <a
        title="Erste private banking"
        href="https://www.ersteprivatebanking.cz/cs/sprava-majetku"
        target="_blank"
        rel="noopener noreferrer"
      >
        <PartnerLogo2 />
      </a>
    </Row>
  </Wrapper>
)

const RenderLeftIcon = ({
  leftIconPath,
  hpCols,
  leftIconMobilePath,
  iconConfig,
}) =>
  leftIconPath && (
    <Row>
      {!hpCols && <Gap gap="8px" mobileGap="0" />}
      <Col>
        {!hpCols && (
          <Gap
            gap={`${iconConfig.gapToTop}px`}
            mobileGap={`${iconConfig.gapToTopMobile}px`}
          />
        )}
        {hpCols &&
          <Gap gap={`${iconConfig.gapToTopHp || iconConfig.gapToTop}px`} />}
        <RH desktop>
          <LeftIcon
            {...iconConfig}
            src={hpCols ? leftIconMobilePath || leftIconPath : leftIconPath}
            alt="icon"
            hpCols={hpCols}
          />
        </RH>
        <RH mobile>
          <LeftIcon
            {...iconConfig}
            hpCols={hpCols}
            src={leftIconMobilePath || leftIconPath}
            alt="icon"
          />
        </RH>
      </Col>
    </Row>
  )

function ArticleLinkBox({
  frontmatter = {},
  title,
  buttonText,
  to,
  href,
  bgColor,
  leftIconPath,
  leftIconMobilePath,
  isQuiz,
  boxLeftIconType,
  path,
  maxWidth,
  hpCols,
  hpPhotoGap,
  isPartner,
  isInsideArticle,
  desktopPadded,
  hp,
}) {
  // console.log('allMdx', allMdx, allArticles)

  if (isPartner) {
    return <PartnerBox hpCols={hpCols} />
  }

  buttonText ??=
    frontmatter.buttonText ||
    (frontmatter.companyName
      ? `Příběh firmy ${frontmatter.companyName}`
      : frontmatter.title)

  to ??= path

  if (hp) {
    title ??= frontmatter.boxTitleHp
  }
  title ??= frontmatter.boxTitle || frontmatter.title

  leftIconPath ??= frontmatter.boxLeftIcon?.publicURL

  const iconConfig = iconsConfig[boxLeftIconType || frontmatter.boxLeftIconType]

  const featuredImg = frontmatter.boxPhoto?.image?.full

  bgColor ??=
    frontmatter.bgColor || frontmatter.companyName ? 'white' : 'lightGold'

  const responsiveIconAttrs = {
    hideAfter: iconConfig?.responsive ? 'mobile' : 'origin',
  }
  const defaultIconAttrs = {
    showAfter: iconConfig?.responsive ? 'mobile' : 'origin',
  }
  if (hpCols) {
    if (iconConfig?.responsive) {
      delete responsiveIconAttrs.hideAfter
      defaultIconAttrs.hideAfter = 'origin'
    }
  }

  return (
    <Row>
      {desktopPadded && <Gap gap="64px" mobileGap="0" />}

      <Wrapper
        bgColor={bgColor}
        maxWidth={maxWidth}
        hpCols={hpCols}
        isQuiz={isQuiz}
      >
        {hpCols && isQuiz && <HpQuizIcon src={leftIconPath} alt="" />}
        <Row>
          {/* unrespoonsive icon */}
          {leftIconPath && (!isQuiz || !hpCols) && (
            <RH {...defaultIconAttrs}>
              <Row>
                <RenderLeftIcon
                  leftIconPath={leftIconPath}
                  hpCols={hpCols}
                  leftIconMobilePath={leftIconMobilePath}
                  iconConfig={iconConfig}
                />
                <Gap
                  gap={`${
                    hpCols
                      ? iconConfig.gapToTitleHp || iconConfig.gapToTitle
                      : iconConfig.gapToTitle
                  }px`}
                  mobileGap={`${iconConfig.gapToTitleMobile || 24}px`}
                />
              </Row>
            </RH>
          )}
          <Col alignItems="flex-start">
            {/* responsive icon */}
            {leftIconPath && (
              <RH {...responsiveIconAttrs}>
                <RenderLeftIcon
                  leftIconPath={leftIconPath}
                  hpCols={hpCols}
                  leftIconMobilePath={leftIconMobilePath}
                  iconConfig={iconConfig}
                />

                <Gap gap={`${iconConfig.gapToTitleMobile || 12}px`} />
              </RH>
            )}

            <Link
              to={to}
              href={href}
              target="_blank"
              asA={!!href}
              rel="noopener noreferrer"
              title={buttonText}
            >
              <Title
                weight="800"
                size={
                  isQuiz && hpCols ? '30px' : isInsideArticle ? '22px' : '26px'
                }
                mobileSize={isInsideArticle ? '22px' : '24px'}
                lineHeight={isInsideArticle ? '140%' : '125%'}
                color={isQuiz ? 'white' : 'cyan'}
              >
                {title}
              </Title>
            </Link>

            <Gap gap="8px" />
            {isQuiz && !hpCols && <Gap gap="16px" mobileGap="8px" />}
            {isQuiz && hpCols && <Gap gap="24px" />}

            <Col width={isQuiz && hpCols ? '100%' : 'auto'}>
              <Link
                to={to}
                href={href}
                target="_blank"
                asA={!!href}
                rel="noopener noreferrer"
                title={buttonText}
              >
                <Button type={isQuiz ? 'primary' : 'secondary'}>
                  {buttonText}
                </Button>
              </Link>
            </Col>
          </Col>

          {featuredImg && (
            <Col>
              {!hpCols && (
                <>
                  <Gap gap="170px" onlyWidth basis="0" mobileGap="90px" />
                  <Gap
                    gap="120px"
                    onlyWidth
                    basis="0"
                    mobileGap="0px"
                    bp={480}
                  />
                </>
              )}
              {hpCols && <Gap gap={`${hpPhotoGap}px`} onlyWidth basis="0" />}
            </Col>
          )}
        </Row>
        {featuredImg && (
          <RightGatsbyImage
            image={featuredImg}
            alt=""
            mobileWidth={
              hpCols
                ? frontmatter.boxPhotoHpWidth || frontmatter.boxPhotoMobileWidth
                : frontmatter.boxPhotoMobileWidth
            }
            width={
              hpCols
                ? frontmatter.boxPhotoHpWidth || frontmatter.boxPhotoWidth
                : frontmatter.boxPhotoWidth
            }
          />
        )}
      </Wrapper>
    </Row>
  )
}

export default ArticleLinkBox

export const ArticleLinkBoxForMdx = props => (
  <Col>
    <Gap gap="16px" mobileGap="8px" />
    <ArticleLinkBox {...props} />
    <Gap gap="40px" mobileGap="32px" />
  </Col>
)
