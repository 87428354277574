import React from 'react'
import styled, { css } from 'styled-components'
import { up } from '../../lib/styles'

export default () => (
  <svg
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 9H20" stroke="#C24542" strokeWidth="2" />
    <path d="M13 1L21 9L13 17" stroke="#C24542" strokeWidth="2" />
  </svg>
)
