import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between } from '../lib/styles'
import Col from './Col'
import Gap from './Gap'
import Row from './Row'
import Text, { ArticleText } from './Text'
import QuoteIcon from './icons/QuoteIcon'
import Title from './Title'
import BulletArrow from './icons/BulletArrow'

export const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    background: ${colors.cyan};
    position: relative;
    margin: 0 -24px;
    padding: 32px 24px 40px;

    ${up('mobile')} {
      width: 100vw;
      margin: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 48px 40px 56px;
    }
  `}
`

const cols = [
  'Obchodní korporace, ve které je nadpoloviční počet společníků tvořen členy jedné rodiny a alespoň jeden člen rodiny je členem jejího statutárního orgánu.',
  'Obchodní korporace, v níž většinu hlasovacích práv vykonává ve prospěch jedné rodiny fundace nebo svěřenský správce svěřenského fondu, pokud je alespoň jeden člen rodiny členem statutárního orgánu fundace, nebo svěřenský správce svěřenského fondu.',
  'Rodinná živnost je podnikání, na kterém se svojí prací anebo majetkem podílejí nejméně dva členové jedné rodiny a nejméně jeden z členů rodiny je držitelem živnostenského nebo obdobného oprávnění.',
]

const COLS_BP = 820

function FamilyBusinessDefinition() {
  return (
    <Col>
      <Gap gap="16px" mobileGap="8px" />

      <Wrapper>
        <Col alignSelf="center" width="100%" maxWidth="1144px">
          <Title
            color="gold"
            as="h2"
            size="40px"
            weight="800"
            lineHeight="130%"
            mobileSize="24px"
            textAlign="center"
          >
            Definice rodinného podniku
          </Title>
          <Gap gap="32px" mobileGap="24px" />
          <Row
            responsive
            bp={COLS_BP}
            mobileAlignItems="center"
            alignItems="flex-start"
          >
            {cols.map((col, index) => (
              <>
                {index !== 0 && (
                  <Gap.Fluid
                    max="32px"
                    mobileMax="24px"
                    mobileMin="24px"
                    min="16px"
                    grow="1"
                  />
                )}

                <Row alignItems="flex-start" grow="1" shrink="1">
                  <Col>
                    <Gap gap="4px" />
                    <BulletArrow />
                  </Col>
                  <Gap gap="16px" />
                  <Col
                    grow="1"
                    shrink="1"
                    maxWidth="320px"
                    bp={COLS_BP}
                    mobileMaxWidth="500px"
                  >
                    <ArticleText color="white" padding="0">
                      {col}
                    </ArticleText>
                  </Col>
                </Row>
              </>
            ))}
          </Row>
        </Col>
      </Wrapper>
      <Gap gap="40px" mobileGap="32px" />
    </Col>
  )
}

export default FamilyBusinessDefinition
