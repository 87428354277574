import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between } from '../lib/styles'
import Col from './Col'
import Gap from './Gap'
import Row from './Row'
import Text from './Text'

const StyledList = styled.div`
  ${({ theme: { colors }, noPadding }) => css`
    counter-reset: number;
    ${!noPadding &&
    css`
      padding: 16px 0;
    `}
  `}
`

function ArticleList({ children, noPadding }) {
  return <StyledList noPadding={noPadding}>{children}</StyledList>
}

export default ArticleList
