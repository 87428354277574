/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import Title from '../components/Title'
import Link from '../components/Link'
import { ArticleText } from '../components/Text'
import ArticleQuote from '../components/ArticleQuote'
import AdviceBox from '../components/AdviceBox'
import ArticleQuestion from '../components/ArticleQuestion'
import ArticleItem from '../components/ArticleItem'
import ArticleList from '../components/ArticleList'
import TopFamilyCompanies from '../components/TopFamilyCompanies'
import FamilyBusinessDefinition from '../components/FamilyBusinessDefinition'
import GDPNumbers from '../components/GDPNumbers'
import OwnershipTransfereStats from '../components/OwnershipTransfereStats'
import GDPTable from '../components/GDPTable'
import { ArticleLinkBoxForMdx } from '../components/ArticleLinkBox'

const shortcodes = {
  Quote: ArticleQuote,
  Question: ArticleQuestion,
  AdviceBox,
  Link,
  h2: Title.ArticleSectionTitle,
  // h3: Title.ArticleSectionSubTitle,
  p: ArticleText,
  List: ArticleList,
  Item: ArticleItem,
  LinkBox: ArticleLinkBoxForMdx,
  TopFamilyCompanies,
  GDPTable,
  FamilyBusinessDefinition,
  GDPNumbers,
  OwnershipTransfereStats,
}

const Layout = ({ children }) =>
  <MDXProvider components={shortcodes}>{children}</MDXProvider>

export default Layout
