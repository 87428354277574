import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between } from '../lib/styles'
import Text, { ArticleText } from './Text'
import Col from './Col'
import Title from './Title'
import Gap from './Gap'
import Link from './Link'
import Button from './Button'
import QuoteIcon from './icons/QuoteIcon'

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${colors.lightGold};

    margin: 0 -16px;
    padding: 24px;

    ${up('mobile')} {
      margin: 0;
      padding: 40px;
    }
  `}
`

function AdviceBox({
  children,
  title,
  name,
  position,
  to,
  href,
  target,
  buttonText,
  withQuotes,
}) {
  return (
    <Col>
      <Gap gap="16px" mobileGap="8px" />
      <Wrapper>
        {withQuotes && (
          <>
            <QuoteIcon />
            <Gap gap="16px" mobileGap="16px" />
          </>
        )}
        <Title as="h3" weight="800" size="24px" lineHeight="140%">
          {title}
        </Title>
        <Gap gap="16px" mobileGap="16px" />
        <ArticleText padding="0">{children}</ArticleText>
        <Gap gap="24px" mobileGap="16px" />

        {(to || href) && (
          <Col alignItems="flex-start">
            <Link href={href} to={to} target={target} asA={!!href}>
              <Button type="secondary">{buttonText}</Button>
            </Link>
          </Col>
        )}

        {!to && !href && (
          <>
            <Text
              size="18px"
              lineHeight="150%"
              font="public"
              weight="700"
              color="cyan"
            >
              {name}
            </Text>
            <Gap gap="4px" />
            <Text size="16px" lineHeight="150%" weight="400" color="#85827A">
              {position}
            </Text>
          </>
        )}
      </Wrapper>
      <Gap gap="40px" mobileGap="32px" />
    </Col>
  )
}

export default AdviceBox
