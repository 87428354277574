import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between } from '../lib/styles'
import Col from './Col'
import Title from './Title'
import Text from './Text'
import Gap from './Gap'
import Row from './Row'
import { ListItemMarker } from './ArticleItem'

const companies = {
  czech: [
    ['Czechoslovak Group', 'Strnadovi'],
    ['DEK', 'Kutnarovi'],
    ['Mattoni 1873', 'Pasqualeovi'],
    ['HP Tronic', 'Hradilovi'],
    ['Promet Czech', 'Materovi'],
  ],
  world: [
    ['Walmart', 'Waltonovi'],
    ['Volkswagen', 'Piechovi a Porscheovi'],
    ['Berkshire Hathaway Inc.', 'Buffettovi'],
    ['Exnor N.V.', 'Agnelliovi'],
    ['Ford Motor Company', 'Fordovi'],
  ],
}

const ArticleFullWidthWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    background: ${colors.cyan};
    position: relative;
    margin: 0 -24px;
    padding: 32px 24px 40px;

    ${up('mobile')} {
      width: 100vw;
      margin: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 40px 40px 48px;
    }
  `}
`

const ColumnWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    counter-reset: number;
  `}
`

const Column = ({ title, items }) => (
  <Col width="100%" maxWidth="326px">
    <Title color="gold" as="span" size="18px" weight="800" lineHeight="140%">
      {title}
    </Title>
    <Gap gap="16px" mobileGap="16px" />
    <ColumnWrapper>
      {items.map(([company, family], index) => (
        <>
          {index !== 0 && <Gap gap="14px" mobileGap="16px" />}
          <Row alignItems="center">
            <ListItemMarker />
            <Gap gap="16px" />

            <Col>
              <Text
                color="white"
                weight="700"
                size="18px"
                lineHeight="150%"
                font="public"
                mobileSize="18px"
              >
                {company}
              </Text>
              <Text
                color="white"
                weight="400"
                size="18px"
                lineHeight="150%"
                opacity={0.5}
              >
                {family}
              </Text>
            </Col>
          </Row>
        </>
      ))}
    </ColumnWrapper>
  </Col>
)

function TopFamilyCompanies() {
  return (
    <Col>
      <Gap gap="16px" mobileGap="8px" />

      <ArticleFullWidthWrapper>
        <Col alignSelf="center" width="100%" maxWidth="720px">
          <Title
            color="gold"
            as="h2"
            size="28px"
            weight="800"
            lineHeight="130%"
            mobileSize="22px"
            mobileLineHeight="140%"
          >
            Největší rodinné firmy
          </Title>
          <Gap gap="16px" mobileGap="16px" />
          <Row responsive>
            <Column title="v Česku" items={companies.czech} />

            <Gap gap="80px" mobileGap="32px" />

            <Column title="ve světě" items={companies.world} />
          </Row>
        </Col>
      </ArticleFullWidthWrapper>
      <Gap gap="40px" mobileGap="32px" />
    </Col>
  )
}

export default TopFamilyCompanies
