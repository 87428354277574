import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between, fontDim } from '../lib/styles'
import Gap from './Gap'
import Link from './Link'

import Logo from '../data/images/top-bar/logo.inline.svg'
import Button from '../data/images/top-bar/btn.inline.svg'

const Wrapper = styled.div`
  position: relative;
`

const TopBar = styled.div`
  background: #222;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 36px;
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
`

const TopBarContainer = styled.div`
  ${({ theme: { sizes, pageSidePadding, mobilePageSidePadding } }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    opacity: 0.8;

    ${up('mobile')} {
      padding: 0 ${pageSidePadding};
    }
  `}
`

const TopBarContainerInside = styled.div`
  ${({ theme: { sizesPx } }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: ${sizesPx.pageWidth};

    ${up('mobile')} {
    }
  `}
`

const ButtonWrapper = styled.div`
  ${({}) => css`
    position: relative;

    ${up('mobile')} {
    }
  `}
`

const TopBarLogo = styled(Logo)`
  height: 30px;
  width: auto;
  ${up('mobile')} {
    height: 36px;
  }
`

const StyledButton = styled(Button)`
  #btn-fill,
  #btn-text {
    transition: 0.1s;
  }
  &:hover {
    #btn-fill {
      fill: #222;
      stroke: white;
    }
    #btn-text {
      fill: white;
    }
  }

  ${up('mobile')} {
  }
`

function SpecialTopBar() {
  return (
    <Wrapper>
      <Gap gap="36px" />
      <TopBar>
        <TopBarContainer>
          <TopBarContainerInside>
            <Link to={Link.HOMEPAGE} title="Jít na úvodní stránku">
              <TopBarLogo />
            </Link>
            <Link
              href="https://forbes.cz"
              asA
              title="Jít na Forbes.cz"
              target="_blank"
              rel="noreferrer"
            >
              <ButtonWrapper>
                <StyledButton />
              </ButtonWrapper>
            </Link>
          </TopBarContainerInside>
        </TopBarContainer>
      </TopBar>
    </Wrapper>
  )
}

export default SpecialTopBar
