import React from 'react'
import styled, { css } from 'styled-components'
import { addResponsivity, up } from '../lib/styles'

const Row = styled.div`
  ${({
    theme: { colors },
    responsive,
    alignItems,
    alignSelf,
    justifyContent,
    mobileAlignItems,
    maxWidth,
    mobileMaxWidth,
    position,
    margin,
    mobileMargin,
    padding,
    mobilePadding,
    wrap,
    width,
    shrink,
    grow,
    mobileWidth,
    height,
    bp = 'mobile',
    mobileFlexDirection,
  }) => css`
    display: flex;
    align-items: ${mobileAlignItems || alignItems};
    align-self: ${alignSelf};
    justify-content: ${justifyContent};
    max-width: ${mobileMaxWidth || maxWidth};
    width: ${mobileWidth || width};
    height: ${height};
    position: ${position};
    flex-shrink: ${shrink};
    flex-grow: ${grow};
    margin: ${mobileMargin || margin};
    padding: ${mobilePadding || padding};
    flex-direction: ${mobileFlexDirection || (responsive ? 'column' : 'row')};

    ${up(bp)} {
      width: ${width};
      flex-direction: row;
      margin: ${margin};
      padding: ${padding};
      align-items: ${alignItems};
      max-width: ${maxWidth};
    }
/* 
    ${responsive &&
      css`
        flex-wrap: wrap;
      `} */

    flex-wrap: ${wrap};


    ${addResponsivity({ displayAs: 'flex' })}
  `}
`

export default Row
