// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-quiz-template-js": () => import("./../../../src/templates/QuizTemplate.js" /* webpackChunkName: "component---src-templates-quiz-template-js" */),
  "component---src-templates-result-template-js": () => import("./../../../src/templates/ResultTemplate.js" /* webpackChunkName: "component---src-templates-result-template-js" */)
}

