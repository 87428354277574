import React from 'react'
import { createGlobalStyle, ThemeProvider, css } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { theme } from '../lib/styles'
import '../lib/fragments' // has to be imported somewhere to gatsby notice that
import { getFontsCss } from '../lib/fonts'
import { fonts, variableFonts } from '../data/fonts/fontFiles'

const GlobalStyle = createGlobalStyle`${({ theme: { colors } }) => css`
  ${styledNormalize}
  ${fontsCss}

  html {
    /* scroll-behavior: smooth; */
  }

  body {
    background: white;
    font-family: 'Noto Serif', serif;
    color: ${colors.text};
    min-height: 100vh;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`}`

// eslint-disable-next-line react/prefer-stateless-function
class Providers extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          {this.props.children}
        </>
      </ThemeProvider>
    )
  }
}

export default Providers

const fontsCss = css`
  ${getFontsCss({ variableFonts, fonts })}
`
