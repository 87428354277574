import React from 'react'
import styled, { css } from 'styled-components'
import { up } from '../../lib/styles'

export default () => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L7 7L1 13" stroke="white" strokeWidth="2" />
  </svg>
)
