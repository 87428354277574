import React from 'react'
import styled, { css } from 'styled-components'
import { up } from '../../lib/styles'

const StyledSvg = styled.svg`
  width: 40px;
  height: 32px;
  ${up('mobile')} {
    width: 50px;
    height: 40px;
  }
`

export default () => (
  <StyledSvg viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>quote icon</title>
    <path
      d="M23.0479 14.377C23.0479 17.1459 22.628 19.8296 21.7884 22.4281C20.9488 25.0266 19.6264 27.4334 17.8212 29.6486C15.974 31.8637 13.602 33.8445 10.7053 35.5911C7.76659 37.3802 4.19815 38.8498 3.8147e-06 40L3.22257e-06 33.2268C1.84719 32.5453 3.48447 31.8637 4.91184 31.1821C6.33921 30.5005 7.53569 29.7551 8.50126 28.9457C9.42485 28.1789 10.1385 27.3056 10.6423 26.3259C11.1041 25.3887 11.335 24.2812 11.335 23.0032C11.335 22.1512 11.1041 21.4696 10.6423 20.9585C10.1805 20.4473 9.59278 19.9574 8.8791 19.4888C8.16541 19.0202 7.40974 18.5304 6.61209 18.0192C5.81444 17.5506 5.05877 16.9329 4.34509 16.1661C3.6314 15.442 3.04366 14.5048 2.58186 13.3546C2.12007 12.2045 1.88917 10.7561 1.88917 9.00959C1.88917 6.07029 2.79177 3.83387 4.59698 2.30032C6.3602 0.766773 8.56423 -1.92268e-07 11.2091 -4.23487e-07C14.8615 -7.42789e-07 17.7582 1.27795 19.8992 3.83386C21.9983 6.38978 23.0479 9.90415 23.0479 14.377ZM50 14.377C50 17.1459 49.5802 19.8296 48.7406 22.4281C47.9009 25.0266 46.5785 27.4334 44.7733 29.6486C42.9261 31.8637 40.5542 33.8445 37.6574 35.5911C34.7187 37.3802 31.1503 38.8498 26.9521 40L26.9521 33.2268C28.8413 32.5453 30.4996 31.8637 31.927 31.1821C33.3123 30.5005 34.4878 29.7551 35.4534 28.9457C36.377 28.1789 37.0907 27.3056 37.5945 26.3259C38.0563 25.3887 38.2872 24.2811 38.2872 23.0032C38.2872 22.1512 38.0563 21.4696 37.5945 20.9585C37.1327 20.4473 36.5449 19.9574 35.8312 19.4888C35.1175 19.0202 34.3619 18.5304 33.5642 18.0192C32.7666 17.5506 32.0109 16.9329 31.2972 16.1661C30.5835 15.442 29.9958 14.5048 29.534 13.3546C29.0722 12.2045 28.8413 10.7561 28.8413 9.00958C28.8413 6.07029 29.7439 3.83386 31.5491 2.30032C33.3123 0.766771 35.5164 -2.5485e-06 38.1612 -2.77972e-06C41.8136 -3.09902e-06 44.7103 1.27795 46.8514 3.83386C48.9505 6.38977 50 9.90415 50 14.377Z"
      fill="#C24542"
    />
  </StyledSvg>
)
