import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between } from '../lib/styles'
import Col from './Col'
import Gap from './Gap'
import Row from './Row'
import Text, { ArticleText } from './Text'
import QuoteIcon from './icons/QuoteIcon'
import Title from './Title'
import BulletArrow from './icons/BulletArrow'

export const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    background: ${colors.cyan};
    position: relative;
    margin: 0 -24px;
    padding: 32px 8px 32px;

    ${up('mobile')} {
      width: 100vw;
      margin: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 48px 40px 32px;
    }
  `}
`

export const RedLine = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100%;
    height: 0px;

    border: 1px dashed ${colors.red};

    ${up('mobile')} {
    }
  `}
`

const TitleText = ({ children, ...rest }) => (
  <Text
    font="public"
    weight="600"
    size="22px"
    mobileSize="18px"
    lineHeight="150%"
    color="white"
    {...rest}
  >
    {children}
  </Text>
)

const NumbersText = ({ children }) => (
  <Text
    weight="700"
    size="40px"
    lineHeight="100%"
    color="white"
    mobileSize="32px"
  >
    {children}
  </Text>
)
const LineText = ({ children }) =>
  <TitleText lineHeight="100%">{children}</TitleText>

const RowLine = ({ from, preposition = 'ze', percentage }) => (
  <Row justifyContent="space-between" alignItems="baseline">
    <Row alignItems="baseline" shrink="0">
      {preposition !== 'ze' && <Gap gap="12px" mobileGap="10px" />}
      <LineText>{preposition}</LineText>
      <Gap gap="8px" mobileGap="4px" />
      <NumbersText>{from}.</NumbersText>
      <Gap gap="8px" mobileGap="4px" />
      <LineText>na</LineText>
      <Gap gap="8px" mobileGap="4px" />
      <NumbersText>{from + 1}.</NumbersText>
    </Row>
    <Gap gap="8px" mobileGap="4px" />
    <RedLine />
    <Gap gap="8px" mobileGap="4px" />
    <Col shrink="0">
      <NumbersText>{percentage} %</NumbersText>
    </Col>
  </Row>
)

function OwnershipTransfereStats() {
  return (
    <Col>
      <Gap gap="16px" mobileGap="8px" />

      <Wrapper>
        <Col alignSelf="center" width="100%" maxWidth="720px">
          <Title
            color="gold"
            as="h2"
            size="28px"
            weight="800"
            lineHeight="130%"
            mobileSize="24px"
            textAlign="center"
          >
            Globální úspěšnost předání rodinného byznysu mezi generacemi
          </Title>
          <Gap gap="32px" mobileGap="24px" />

          <Col alignSelf="center" width="100%" maxWidth="600px">
            <Row justifyContent="space-between" alignItems="flex-end">
              <TitleText>Předání mezi generacemi</TitleText>
              <Gap gap="16px" />
              <TitleText textAlign="right">Přeživší rodinné firmy</TitleText>
            </Row>
            <Gap gap="16px" mobileGap="16px" />
            <RowLine from={1} preposition="z" percentage="30" />
            <Gap gap="24px" mobileGap="24px" />
            <RowLine from={2} percentage="12" />
            <Gap gap="24px" mobileGap="24px" />
            <RowLine from={3} percentage="3" />
          </Col>
          <Gap gap="56px" mobileGap="40px" />

          <Text
            weight="400"
            size="16px"
            color="white"
            textAlign="center"
            opacity="0.5"
          >
            Zdroj: Family Business Institute
          </Text>
        </Col>
      </Wrapper>
      <Gap gap="40px" mobileGap="32px" />
    </Col>
  )
}

export default OwnershipTransfereStats
