import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between } from '../lib/styles'
import Col from './Col'
import Title from './Title'
import Text from './Text'
import Gap from './Gap'
import Row from './Row'
import { ListItemMarker } from './ArticleItem'

const countries = [
  ['Rakousko', 80, 75],
  ['Francie', 83, 46],
  ['Německo', 79, 45],
  ['Velká Británie', 66, 33],
  ['USA', 80, 60],
  ['Maďarsko', 70, 52],
  ['Česko', 50, 75],
  ['Slovensko', 38, 72],
]
const Table = styled.table`
  ${({ theme: { colors } }) => css`
    border-collapse: collapse;
    border-spacing: 0;

    margin: 0;
    padding: 0;

    width: 100%;

    tbody,
    thead,
    tr,
    th,
    td {
      margin: 0;
      font-size: 0;
      font: inherit;
    }
  `}
`

const Wrapper = styled.div`
  ${({ theme: { colors, mobilePageSidePadding } }) => css`
    display: flex;
    flex-direction: column;
    background: ${colors.cyan};
    padding: 32px 8px 30px;
    font-size: 0;
    margin: 0 -${mobilePageSidePadding};

    ${up(600)} {
      margin: 0;
    }

    ${up('mobile')} {
      padding: 40px 56px 48px;
    }
  `}
`

const StyledTh = styled.th`
  ${({ theme: { colors }, maxWidth }) => css`
    text-align: left;
    padding: 0 8px 10px;
    max-width: ${maxWidth};
    vertical-align: bottom;

    ${up(370)} {
      padding: 0 16px 10px;
    }
    ${up('mobile')} {
      padding: 0 8px 10px;
    }
  `}
`
const Tr = styled.tr`
  ${({ theme: { colors } }) => css`
    border-top: solid 1px rgba(255, 255, 255, 0.24);

    ${up('mobile')} {
    }
  `}
`
const StyledTd = styled.td`
  ${({ theme: { colors } }) => css`
    padding: 10px 8px;

    ${up(370)} {
      padding: 10px 16px;
    }

    ${up('mobile')} {
      padding: 10px 8px;
    }
  `}
`

const Th = ({ children, maxWidth }) => (
  <StyledTh maxWidth={maxWidth}>
    <Text
      color="gold"
      font="public"
      size="16px"
      weight="500"
      lineHeight="150%"
      mobileLineHeight="140%"
    >
      {children}
    </Text>
  </StyledTh>
)
const Td = ({ children }) => (
  <StyledTd>
    <Text
      color="white"
      font="public"
      size="18px"
      weight="700"
      lineHeight="150%"
    >
      {children}
    </Text>
  </StyledTd>
)

function GDPTable() {
  return (
    <Col>
      <Gap gap="16px" mobileGap="8px" />
      <Wrapper>
        <Row>
          <Gap gap="0" mobileGap="8px" />
          <Gap gap="0" mobileGap="8px" showBetween={[370, 'mobile']} />
          <Title
            color="gold"
            as="h2"
            size="28px"
            weight="800"
            lineHeight="130%"
            mobileSize="24px"
            mobileLineHeight="130%"
          >
            Jak se rodinné firmy podílejí na HDP?
          </Title>
          <Gap gap="0" mobileGap="16px" />
        </Row>
        <Gap gap="24px" mobileGap="8px" />
        <Table>
          <thead>
            <tr>
              <Th>Země</Th>
              <Th>Podíl na HDP</Th>
              <Th maxWidth="150px">Podíl na pracovních místech</Th>
            </tr>
          </thead>
          <tbody>
            {countries.map(([country, gdp, workPlaces]) => (
              <Tr>
                <Td>{country}</Td>
                <Td>{gdp} %</Td>
                <Td>{workPlaces} %</Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Wrapper>
      <Gap gap="40px" mobileGap="32px" />
    </Col>
  )
}

export default GDPTable
